import { apiAddress } from "constants/apiAddress";
import { LocalStorageKeys } from 'constants/localStorageKeys';


export const retrieveDeviceValuesByToken = async (token: string) =>
    await fetch(`${apiAddress}/contractors/presigned/retrieve_device_values_by_presigned_token?token=${token}`, {
        method: "GET",
        headers:{
            // "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
            "Content-Type": "application/json"
            }})
        .then((response) => response.json());


    
export const retrieveDeviceMetricsByToken = async (token: string) =>
    await fetch(`${apiAddress}/contractors/presigned/retrieve_device_metrics_by_presigned_token?token=${token}`, {
        method: "GET",
        headers:{
            // "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
            "Content-Type": "application/json"
            }})
        .then((response) => response.json());



export const createPresignedDevicePage= async (device_id: string, start_date: Date, end_date: Date, expiresInHours: number) =>
    await fetch(`${apiAddress}/contractors/presigned/generate_presigned_device_page_path?device_id=${device_id}&start_date=${start_date.toISOString().split("T")[0]}&end_date=${end_date.toISOString().split("T")[0]}&expires_in_hours=${expiresInHours}`, {
        method: 'POST',
        headers:{
        "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
        "Content-Type": "application/json"
        }
    })
    .then((response) => response.ok ? response.json() : false)
    .catch((reason) => console.error(reason));

// export const retrieveDeviceMetricsByToken = async (token: string) =>
//     await fetch(`${apiAddress}/contractors/presigned/retrieve_device_metrics_by_presigned_token?token=${token}`, {
//         method: "GET",
//         headers:{
//             // "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
//             "Content-Type": "application/json"
//             }})
//         .then((response) => response.json());
