import React, { useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Button, TextField, CircularProgress, Typography, Alert } from '@mui/material';

import { createPresignedDevicePage } from 'api/presigned';

const getCurrentDomain = (): string => {
    return `${window.location.protocol}/${window.location.host}`;
};

interface CreatePresignedDeviceFormProps {
  device_id: string;
}

export const CreatePresignedDeviceForm: React.FC<CreatePresignedDeviceFormProps> = ({ device_id }) => {
  const [comment, setComment] = useState<string>('');
  const [expiresInHours, setExpiresInHours] = useState<number | ''>(24);
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<string | null>(null);
  const [presignedLink, setPresignedLink] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  const currentDate = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 3);

  const [startDate, setStartDate] = useState<Date | null>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date | null>(currentDate);

  const handleSubmit = async () => {
    if (!startDate || !endDate || expiresInHours === '') {
      alert("Необходимо заполнить все требуемые поля");
      return;
    }
    
    setLoading(true);
    setResult(null); // Reset result on new request
    setError(false); // Reset error state

    const link = await createPresignedDevicePage(device_id, startDate, endDate, expiresInHours);
    
    setLoading(false);
    if (link) {
      setResult("Страница успешно создана! Вы можете скопировать ссылку нажатием на кнопку"); // Success message
      const fullLink = `${getCurrentDomain()}${link}`
      setPresignedLink(fullLink)
    } else {
      setResult("Failed to create device page."); // Error message
      setError(true);
    }
  };

  const handleCopy = () => {
    if (presignedLink) {
      navigator.clipboard.writeText(presignedLink);
    }
  };

  return (
    <Box sx={{ marginTop: 2, borderRadius: '8px', }}>
      
      <Box marginBottom="1rem" display="flex" alignItems="center" justifyContent="space-between">
        <DatePicker
          label="Начальная дата"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          sx={{ marginRight: '1rem', flex: 1 }}
        />
        <DatePicker
          label="Конечная дата"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          sx={{ flex: 1 }}
        />
      </Box>

      <TextField
        label="Срок действия в часах"
        type="number"
        value={expiresInHours}
        onChange={(e) => setExpiresInHours(e.target.value === '' ? '' : Number(e.target.value))}
        fullWidth
        required
        margin="normal"
      />

      <TextField
        label="Комментарий"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading} sx={{ marginTop: '1rem' }}>
        Создать
      </Button>
      
      {loading && <CircularProgress sx={{ marginTop: '1rem' }} />}
      
      {result && (
        <Box sx={{ marginTop: '1rem'}}>
          <Alert 
            severity={error ? "error" : "success"} 
            action={
              <Button color="inherit" sx={{ display: 'flex', alignItems: 'center' }} onClick={handleCopy}>
                Копировать ссылку
              </Button>
            }
          >
            {result}
          </Alert>
        </Box>
      )}
    </Box>
  );
}