import React, { useEffect, useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getDeviceHourlyMetrics } from 'api/retrieve_metrics';
import { BoxPlot } from '../BoxPlot';
import Alert from '@mui/material/Alert'; // Import Alert component
import { retrieveDeviceMetricsByToken } from 'api/presigned';

import { jwtDecode } from 'jwt-decode';


interface JWTDecodePayload {
    device_id: string,
    comment: string,
    start_date: string,
    end_date: string
    // Add any other fields you expect in your payload
  }
  
const extractPayloadFromJWT = (token: string): JWTDecodePayload => {
  try {
    const decoded = jwtDecode<JWTDecodePayload>(token);
    return decoded; // This will return the payload as a dictionary
  } catch (error) {
    console.error('Invalid token:', error);
    throw new Error('Failed to decode token');
  }
};

interface MetricsData {
    measured_value: string;
    timestamps: string[][];
    mean: number[][];
    median: number[][];
    stddev: number[][];
    q1: number[][];
    q3: number[][];
    max: number[][];
    min: number[][];
    point_names: string[];
    shown_names: string[];
}

interface PresignedDeviceAnalyticsProps {
  token: string;
}

export const PresignedDeviceAnalytics: React.FC<PresignedDeviceAnalyticsProps> = ({ token }) => {
  const [hourlyData, setHourlyData] = useState<MetricsData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Error state

  const [presignedData, setDeviceData] = useState<MetricsData | null>(null)

  const currentDate = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 3);

  const payload = extractPayloadFromJWT(token)

  const device_id = payload.device_id


  const fetchMetrics = async () => {
    setLoading(true);
    setError(null); // Reset error state
    try {
        const data = await retrieveDeviceMetricsByToken(token);
        setHourlyData(data);
    } catch (error) {
      console.error('Error fetching device', error);
      setError('Ошибка при загрузке данных. Пожалуйста, попробуйте позже.'); // Set error state
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchMetrics();
    };
    fetchAllData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>} {/* Show error alert */}        
      {hourlyData && hourlyData.length === 0 ? (
        <Alert severity="info">Данные за выбранный период отсутствуют</Alert>
      ) : (
        hourlyData && hourlyData.map((plotData, index) => (
          <div key={index}>
            <BoxPlot plot_data={plotData} />
          </div>
        ))
      )}
    </>
  );
}