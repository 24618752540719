import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { CreatePresignedDeviceForm } from './CreatePresignedDeviceForm';

interface CreatePresignedDeviceButtonProps {
    device_id: string;
  }
  
  const CreatePresignedDeviceButton: React.FC<CreatePresignedDeviceButtonProps> = ({ device_id }) => {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ShareIcon />}
          onClick={handleOpen}
        >
          Поделиться данными
        </Button>
  
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="share-device-modal-title"
          aria-describedby="share-device-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "70%",
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography id="share-device-modal-title" variant="h6" component="h2">
              Поделиться данными аналитики
            </Typography>
            <CreatePresignedDeviceForm device_id={device_id} />
          </Box>
        </Modal>
      </>
    );
  };
  
  export default CreatePresignedDeviceButton;