import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Typography, Grid, Paper } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import InfoIcon from '@mui/icons-material/Info';


import { jwtDecode } from 'jwt-decode';


interface JWTDecodePayload {
    device_id: string,
    comment: string,
    start_date: string,
    end_date: string
    // Add any other fields you expect in your payload
  }
  
const extractPayloadFromJWT = (token: string): JWTDecodePayload => {
  try {
    const decoded = jwtDecode<JWTDecodePayload>(token);
    return decoded; // This will return the payload as a dictionary
  } catch (error) {
    console.error('Invalid token:', error);
    throw new Error('Failed to decode token');
  }
};


interface PresignedDeviceDataProps {
  token: string;
}

export const PresignedDeviceData: React.FC<PresignedDeviceDataProps> = ({ token }) => {

  const currentDate = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 3);

  const payload = extractPayloadFromJWT(token)

  return (
    <>
        <Box>
      <Paper elevation={3}>
        <Box p={2}>
          {/* Header */}
          <Box display="flex" alignItems="center" borderBottom={1} pb={1} mb={2}>
            <DeviceHubIcon color="primary" />
            <Typography variant="h5" component="h2" ml={1}>
              Детали
            </Typography>
          </Box>

          {/* Device Details */}
          <Box display="flex" alignItems="center" pb={1}>
            <InfoIcon color="primary" />
            <Typography variant="h6" component="h2" ml={1}>
              Общая информация
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Device ID
              </Typography>
              <Typography variant="body1">{payload.device_id}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Имя семейства
              </Typography>
              <Typography variant="body1">{}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Комментарий
              </Typography>
              <Typography variant="body1">{payload.comment}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
    </>
  );
}